import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import HeadHelmet from "../components/HeadHelmet/HeadHelmet";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <HeadHelmet
          title='Privacy Policy'
          description='SnoreFreeSwiss.ch Privacy Policy document'
        />

        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9 heading-color">
                    Privacy Policy
                  </h2>
                  {/* <p className="gr-text-8 mb-13 text-color-opacity">
                    By accessing and placing an order with UXTheme, you confirm
                    that you are in agreement with and bound by the terms and
                    conditions
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 fw-bold pb-3 heading-color">Introduction</h3>
                    <p className="gr-text-9 text-color-opacity">
                    Welcome to the privacy policy page for our website, which is dedicated to providing information about anti-snoring systems. Your privacy is important to us, and we want to ensure that you have a clear understanding of how we collect, use, and protect your personal information. This privacy policy outlines the types of information we gather, how we use it, and the steps we take to safeguard your privacy.                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 fw-bold pb-3 heading-color">Data Controller</h3>
                    <p className="gr-text-9 text-color-opacity">
                      The data controller responsible for the processing of personal information on our website is Antonio de Gregorio, located at Herostrasse 9 CH-8048 Zürich. If you have any questions or concerns regarding our privacy policy or the handling of your personal information, please feel free to contact us through our website's contact form or by email at info@protheslab3d.ch.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 fw-bold pb-3 heading-color">Information Collection and Use</h3>
                    <p className="gr-text-9 text-color-opacity">
                      <h4 className="gr-text-8 fw-bold pb-3 heading-color">Personal Information</h4>
                      <p className="gr-text-9 text-color-opacity">
                        When you submit a contact form on our website, we collect personal information such as your name, email address, and phone number. This information is used to respond to your inquiries and provide you with information about our anti-snoring products.
                      </p>
                      <h4 className="gr-text-8 fw-bold pb-3 heading-color">Non-Personal Information</h4>
                      <p className="gr-text-9 text-color-opacity">
                        We also collect non-personal information through the use of Google Analytics. This information helps us understand how visitors use our website, which pages are most popular, and how we can improve the user experience. Non-personal information may include your IP address, browser type, and operating system.                    </p>
                      </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 fw-bold pb-3 heading-color">Use of Cookies</h3>
                    <p className="gr-text-9 text-color-opacity">
                      Our website uses cookies to enhance your browsing experience. Cookies are small text files that are stored on your computer or mobile device when you visit a website. They help the website remember your preferences and enable certain functions, such as keeping you logged in.
                      You can choose to disable cookies in your browser settings. However, doing so may affect the functionality of our website and your ability to access certain features.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 fw-bold pb-3 heading-color">Google Analytics</h3>
                    <p className="gr-text-9 text-color-opacity">
                      Our website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. By using our website, you agree to the terms and conditions of this privacy policy. If you do not agree, please refrain from using our website.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 fw-bold pb-3 heading-color">Data Protection</h3>
                    <p className="gr-text-9 text-color-opacity">
                      <h4 className="gr-text-8 fw-bold pb-3 heading-color">Security Measures</h4>
                      <p className="gr-text-9 text-color-opacity">
                        We take the protection of your personal information seriously and implement appropriate security measures to safeguard your data from unauthorized access, disclosure, alteration, or destruction. These measures include the use of secure servers and encryption technologies.
                      </p>
                      <h4 className="gr-text-8 fw-bold pb-3 heading-color">Third-Party Access</h4>
                      <p className="gr-text-9 text-color-opacity">
                        We will not share your personal information with any third parties without your consent, except as required by law or to protect our rights and property. In the event we engage with third-party service providers to perform certain functions on our behalf, such as email services, we will ensure they operate under strict data protection and confidentiality requirements.
                      </p>
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 fw-bold pb-3 heading-color">Contact Us</h3>
                    <p className="gr-text-9 text-color-opacity">
                    If you have any questions or concerns regarding our privacy policy or the handling of your personal information, please feel free to contact us through our website's contact form.                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <p className="gr-text-9 text-color-opacity">
                      Last update: 22/03/2023
                    </p> 
                   </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
